<template>
  <div class="accuse-home">
    <el-card style="margin: 10px 0px">
      <el-row :gutter="20">
        <el-col :span="4">
          <el-input size="mini" placeholder="请输入标题" v-model="queryInfo.title" clearable></el-input>
        </el-col>
        <el-col v-permission="['ROLE_ADMIN']" :span="3">
          <el-select size="mini" v-model="queryInfo.handle" clearable placeholder="请选择处理状态">
            <el-option key="1" label="未处理" value="false"></el-option>
            <el-option key="2" label="已处理" value="true"></el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
            <el-select size="mini" v-model="queryInfo.accuseType" clearable placeholder="请选择举报类型">
              <el-option v-for="item in accuseTypeList" :key="item.id" :label="item.typeName" :value="''+item.id"></el-option>
           </el-select>
        </el-col>    
        <el-col :span="3">
          <el-button type="primary" icon="el-icon-search" size="mini" @click="getArticleAccuse">搜索</el-button>
        </el-col>
      </el-row>
      <el-table size="mini" :data="accuseList" border style="margin-top: 10px">
        <!-- 添加展开列 -->
        <!-- <el-table-column type="expand"></el-table-column> -->
        <el-table-column type="index" label="#"></el-table-column>
        <el-table-column  v-if="this.permission(['ROLE_ADMIN'])" label="举报人" width="80px" prop="username"></el-table-column>
        <el-table-column  v-if="this.permission(['ROLE_ADMIN'])" label="被举报用户" width="100px" prop="accuseAccount"></el-table-column>
        <el-table-column  v-if="this.permission(['ROLE_ADMIN'])" label="举报文章编号" prop="articleId"></el-table-column>
        <el-table-column label="举报文章标题" prop="title"></el-table-column>
        <el-table-column label="举报内容" prop="accuseConent"></el-table-column>
        <el-table-column label="举报类型" prop="typeName"></el-table-column>
        <el-table-column label="提示信息" width="250px" prop="tip"></el-table-column>
        <el-table-column  width="100px"  v-if="this.permission(['ROLE_USER'])" label="是否确实违规" prop="sure">
            <template slot-scope="scope">
            <el-tag type="danger" v-if="scope.row.sure">违规</el-tag>
            <el-tag  v-else>不违规</el-tag>
          </template>
        </el-table-column>
        <el-table-column  width="100px" v-if="this.permission(['ROLE_ADMIN'])" label="是否确实违规" prop="sure">
            <template slot-scope="scope">
            <el-switch
              active-color="#ff4949"
              v-model="scope.row.sure"
              @change="changeSureStatus(scope.row.id,scope.row.sure)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column width="100px" v-if="this.permission(['ROLE_ADMIN'])" label="是否已处理" prop="handle" >
          <template slot-scope="scope">
            <el-switch
              active-color="#13ce66"
              v-model="scope.row.handle"
              @change="changeHandleStatus(scope.row.id,scope.row.handle)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="违规时间" width="150px" prop="createTime"></el-table-column>
        <el-table-column label="操作" width="190px">
          <template slot-scope="scope">
            <router-link
              target="_blank"
              :to="{name:'articleInfo',params:{articleId: scope.row.articleId}}"
            >
              <el-button size="mini" type="primary" icon="el-icon-view">浏览</el-button>
            </router-link>
            <router-link
              :to="{name:'articleEdit',params:{articleId: scope.row.articleId}}"
            >
              <el-button style="margin-left:5px;" size="mini" type="primary" icon="el-icon-edit">修改</el-button>
            </router-link>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "artilceAccuse",
  data() {
    return {
      queryInfo: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      accuseList: [],
      accuseTypeList: []
    };
  },
  created() {
    this.getArticleAccuse();
    this.getArticleAccuseType();
  },
  methods: {
    // 举报信息列表
    async getArticleAccuse() {
      const { data: res } = await this.$api.getArticleAccuse(this.queryInfo);
      this.total = res.data.total;
      this.accuseList = res.data.list;
    },
    // 获取举报类型
    async getArticleAccuseType() {
      const { data: res } = await this.$api.getArticleAccuseType();
      this.accuseTypeList = res.data;
    },
    // 分页
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getArticleAccuse();
    },
    handleCurrentChange(current) {
      this.queryInfo.pageNum = current;
      this.getArticleAccuse();
    },
    // 改变处理状态状态
    async changeHandleStatus(id, handle) {
      const { data: res } = await this.$api.editArticleAccuse(id, {handle:handle});
      this.getArticleAccuse();
    },
    async changeSureStatus(id, sure){
       const { data: res } = await this.$api.editArticleAccuse(id, {sure:sure});
      this.getArticleAccuse();
    }
  }
};
</script>
<style scoped>
.accuse-home{
  width: 1500px;
  height: 100%;
}
</style>